import './App.css';
import HomeStay from './pages/homeStay';
import Index from './pages/index';
import { BrowserRouter as Router, Routes, Route, } from 'react-router-dom'
import Contact from './pages/contact';
import HomeStayDetailPK from './pages/homestay-detail-bk';
import BookingDetail from './pages/booking-detail';
import FillInformation from './pages/fill-information';
import QrCodePage from './qrCodePage';
import BookingSuccess from './pages/bookingSuccess';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path='/' element={<Index />} />
          <Route path='/homestay' element={<HomeStay />} />
          <Route path='/contact' Component={Contact} />
          <Route path ='/room-detail' Component={HomeStayDetailPK}/>
          <Route path='/booking-detail' Component={BookingDetail} />
          <Route path='/fill-information' Component={FillInformation} />
          <Route path='/qr-page' element={<QrCodePage />} />
          <Route path='/booking-success' element={<BookingSuccess />} />

        </Routes>
      </Router>
      {/* <HomeStay /> */}
    </div>
  );
}

export default App;
